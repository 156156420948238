<template>
	<div class="mt-20 md:mt-0">
		<div
			class="flex items-center justify-between mb-4 bg-verdedark text-white w-full fixed md:static"
		>
			<span class="ml-2 font-bold">Editar Productos</span>
			<div class="flex items-center justify-center">
				<router-link to="/Productos"
					><button
						@click="$emit('click')"
						class="py-2 px-4 inline-flex items-center focus:outline-none font-bold"
					>
						<span class="underline ml-2">Regresar a Producto</span>
					</button></router-link
				>
			</div>
		</div>
		<div v-if="deleteMessage" class="flex justify-center mt-20 md:mt-0">
			<div class="bg-azul w-68 h-40 z-40 absolute rounded-lg shadow-2xl mt-32">
				<div class="flex justify-center py-6">
					<p class="font-bold text-lg text-white">
						¿Confirmar para Eliminar Producto?
					</p>
				</div>
				<div class="flex justify-around">
					<button
						class="border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold pl-56 py-1 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none"
						@click="borrarProducto"
					>
						Eliminar
					</button>
					<button
						class="border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold px-2 py-1 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none"
						@click="deleteMessage = false"
					>
						Cancelar
					</button>
				</div>
			</div>
		</div>
		<div class="flex items-center content-center w-full pt-10 mt-10 md:mt-0">
			<table id="cotizar" class="table-auto mx-auto">
				<thead>
					<tr>
						<th></th>
						<th class="border-2 px-4 py-2" @click="productoFiltro = 'id'">
							ID
						</th>
						<th class="border-2 px-4 py-2" @click="productoFiltro = 'rubro'">
							Rubro
						</th>
						<th class="border-2 px-4 py-2" @click="productoFiltro = 'producto'">
							Producto
						</th>
					</tr>
				</thead>
				<tbody>
					<tr>
						<td scope="row">
							<svg
								@click="deleteMessage = true"
								class="hover:opacity-100 cursor-pointer fill-current text-red-500 opacity-75"
								width="30"
								height="30"
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<g id="delete_sweep_24px">
									<path
										id="icon/content/delete_sweep_24px"
										fill-rule="evenodd"
										clip-rule="evenodd"
										d="M6 4H10L11 5H14V7H2V5H5L6 4ZM5 20C3.89999 20 3 19.1 3 18V8H13V18C13 19.1 12.1 20 11 20H5ZM22 8H15V10H22V8ZM19 16H15V18H19V16ZM15 12H21V14H15V12ZM5 10H11V18H5V10Z"
									/>
								</g>
							</svg>
						</td>
						<td class="border-2 px-4 py-4">
							{{ $route.params.id }}
						</td>
						<td class="border-2 px-4 py-4 w-68">
							<vSelect
								:options="rubrosTotal"
								:placeholder="productoID[0].rubro"
								v-model="rubroNuevo"
							></vSelect>
						</td>
						<td class="border-2 px-4 py-4">
							<input
								type="text"
								:placeholder="
									productoID[0].producto.charAt(0).toUpperCase() +
										productoID[0].producto.slice(1)
								"
								v-model="productoEditado"
							/>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<div v-if="showMessage" class="flex justify-center pt-5">
			<p>{{ message }}</p>
		</div>
		<div class="flex justify-center pt-10">
			<button
				class="border border-primaB rounded-lg bg-prima hover:bg-orange-300 text-black font-bold px-2 py-1 sm:py-2 sm:px-4 inline-flex items-center focus:outline-none"
				@click="verificarProducto"
			>
				Guardar
			</button>
		</div>
	</div>
</template>

<script>
import vSelect from "vue-select";
import "@/assets/styles/vue-select.css";
import gql from "graphql-tag";

export default {
	components: {
		vSelect
	},
	data() {
		return {
			rubrosTotal: [],
			filtroSelect: [],
			rubroActual: "",
			rubroNuevo: "",
			verificado: true,
			productoEditado: "",
			message: "",
			showMessage: false,
			deleteMessage: false,
			productoID: []
		};
	},
	/* apollo: {
    // Query with parameters
    rubrosTotal: {
      query: gql`
        query rubrosTotal($filtro: String!) {
          rubrosTotal(filtro: $filtro) {
            rubro
          }
        }
      `,
      variables: {
        filtro: "rubro"
      },
      fetchPolicy: "no-cache"
    }
  },
  computed: {
    opciones: function() {
      let llenarRubro = [];
      for (let i = 0; i < this.rubrosTotal.length; i++) {
        llenarRubro.push(
          this.rubrosTotal[i].rubro.charAt(0).toUpperCase() +
            this.rubrosTotal[i].rubro.slice(1)
        );
      }
      return llenarRubro;
    } 
  },*/
	mounted() {
		this.listaRubrosTotal();
		this.cargaProducto();
	},
	methods: {
		async cargaProducto() {
			await this.$apollo
				.query({
					query: require("@/graphql/queries/productoID.gql"),
					variables: {
						id: this.$route.params.id
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					this.productoID = data.data.productoID;
				})
				.catch(error => {
					this.message = error;
					this.showMessage = true;
				});
		},
		async listaRubrosTotal() {
			await this.$apollo
				.query({
					query: gql`
						query rubrosTotal($filtro: String!) {
							rubrosTotal(filtro: $filtro) {
								rubro
							}
						}
					`,
					variables: {
						filtro: "rubro"
					},
					fetchPolicy: "no-cache"
				})
				.then(data => {
					for (let i = 0; i < data.data.rubrosTotal.length; i++) {
						this.rubrosTotal.push(data.data.rubrosTotal[i].rubro);
					}
				})
				.catch(error => {
					this.message = error;
					this.showMessage = true;
				});
		},
		verificarProducto: function() {
			if (this.productoEditado != "" && this.rubroNuevo != "") {
				this.$apollo
					.query({
						query: gql`
							query($producto: String!) {
								productoVerificar(producto: $producto) {
									rubro
								}
							}
						`,
						variables: {
							producto: this.productoEditado.toLowerCase()
						}
					})
					.then(data => {
						this.verificado = true;
						this.showMessage = false;
						for (let i = 0; i < data.data.productoVerificar.length; i++) {
							if (
								data.data.productoVerificar[i].rubro.toLowerCase() ===
								this.rubroNuevo.toLowerCase()
							) {
								this.verificado = false;
								this.message = "¡Producto ya existe!";
								this.showMessage = true;
							}
						}
						if (this.verificado) {
							this.guardarProducto();
						}
					})
					.catch(error => {
						this.message = error;
						this.showMessage = true;
					});
			} else {
				this.verificado = false;
				this.message = "Por favor ingrese producto y seleccione rubro.";
				this.showMessage = true;
			}
		},

		guardarProducto: function() {
			this.$apollo
				.mutate({
					mutation: gql`
						mutation($id: ID!, $data: UpdateProducto!) {
							editarProducto(id: $id, input: $data) {
								id
							}
						}
					`,
					variables: {
						id: this.$route.params.id,
						data: {
							rubro: this.rubroNuevo.toLowerCase(),
							producto: this.productoEditado.toLowerCase()
						}
					}
				})
				.then(data => {
					alert(
						"Producto con id:" +
							data.data.editarProducto.id +
							" Modificado con Exito."
					);
					this.$router.push("/productos");
					this.$router.go();
				})
				.catch(error => {
					this.message = error;
					this.showMessage = true;
				});
		},
		borrarProducto: function() {
			this.deleteMessage = false;
			this.$apollo
				.mutate({
					mutation: gql`
						mutation($id: ID!) {
							borrarProducto(id: $id) {
								__typename
							}
						}
					`,
					variables: {
						id: this.$route.params.id
					}
				})
				.then(
					alert(
						"Producto con id:" + this.$route.params.id + " eliminado con exito"
					),
					this.$router.push("/productos"),
					this.$router.go()
				)
				.catch(error => {
					this.message = error + " mutation";
					this.showMessage = true;
				});
		}
	}
};
</script>

<style></style>
